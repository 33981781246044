import globals from "../globals.js"
export default {
    data() {
        return {};
    },
    methods: {
        // Company Settings apis
        addCompanySettings(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.post(globals.AUTH_SERVICE + "/company/settings/add", params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        getCompanySettingsByType(type) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.get(globals.AUTH_SERVICE + "/company/settings?type" + type)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        getListCompanySettings(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.post(globals.AUTH_SERVICE + `/company/settings/list`, params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        updateCompanySettings(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.post(globals.AUTH_SERVICE + `/company/settings/update`, params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        deleteCompanySettings(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.post(globals.AUTH_SERVICE + `/company/settings/delete`, params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },

        // Profile Settings apis
        addSettings(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.post(globals.AUTH_SERVICE + "/settings/add", params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(err) {
                    reject(err.response.data.reason)
                }
            })
        },
        getListSettings(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.post(globals.AUTH_SERVICE + `/settings/list`, params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        deleteSettings(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.post(globals.AUTH_SERVICE + `/settings/delete`, params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },

        // General Settings apis
        async addGeneralSettings(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.post(globals.AUTH_SERVICE + "/profile/general_settings", params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(err) {
                    reject(err.response.data.reason)
                }
            })
        },
        updateGeneralSettings(params, id) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.patch(globals.AUTH_SERVICE + `/profile/general_settings/${id}`, params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
                
            })
        },
        getListGeneralSettings(user_id) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.get(globals.AUTH_SERVICE + `/profile/general_settings/${user_id}`)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        deleteGeneralSettings(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.post(globals.AUTH_SERVICE + `/profile/general_settings/delete`, params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },

        // Company switch APIS
        switchCompany(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.post(globals.AUTH_SERVICE + `/auth/company/switch`, params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
    },
};